export enum ConfigIds {
  'manager-emails' = 22,
  'personnel-list' = 19,
  'css-options' = 23,
  'price-limits' = 9,
  'roles-options' = 14,
  'water-marks' = 27,
  'images-options' = 16,
  'paychase-discount' = 31,
  'related-products-options' = 13,
  'similar-products' = 250,
  'seo-options' = 35,
  'admin-menu-buttons' = 41

}

export interface ConfigItem {
  id: number
  name: string
  value: any
  schema: object
}

/**
 * * Объект плагина
 */
export interface ConfigsPlugin {
  /**
   * * Шаблоны configs
   */
  configs: ConfigItem[]
  // Очистка стора конфига
  resetConfigs: () => void
  /**
   * * Объект конфига
   */
  config: any
  // Очистка стора конфига
  resetConfig: () => void
  /**
   * * Запрос на список configs
   */
  getConfigs: () => Promise<ConfigItem[]>
  /**
   * * Запрос на config по id
   */
  getConfig: (id: ConfigIds, save?: boolean) => Promise<any>
  /**
   * * Запрос на изменение config
   */
  editConfigs: (configParams: ConfigItem[]) => Promise<ConfigItem[]>
  /**
   * * Запрос на изменение config по id
   */
  editConfig: () => Promise<any>
}
